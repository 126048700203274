import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { isMobile, isTablet } from 'react-device-detect';
import usePaymentInfoForm from '../hooks/usePaymentInfoForm';
import { paymentFormKeys, paymentFormPlaceHolders } from '../utils';
import './PaymentInfo.scss';
import { COUNTRY, STATES } from 'shared/utils/kyc/Constants';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import Button from '../../../components/Button/Button';
import { getDefaultCountryValue } from 'shared/utils/kyc/getDefaultCountry';
import FormContentContainer from './FormContentContainer';
import { pwaStyleAdapter, isNotStandaloneMobilePWA } from '../../../utils/mobileSupported';
import useWindowSize from '../../../hooks/useWindowSize';
import useThemeContext from 'shared/context/hooks/useThemeContext';

export default function DefaultPaymentInfo(props) {
  const { onSubmit, nextHandle, errorMessage } = props;
  const { height: windowHeight } = useWindowSize();
  const { PurchaseFlow } = useThemeContext();
  const submitFormHandler = formInputs => {
    onSubmit(nextHandle, formInputs);
  };

  const { values, handleSubmit, handleBlur, handleChange, isValid } = usePaymentInfoForm({
    isKycPassed: true,
    submitFormHandler,
  });

  return (
    <PaymentForm windowHeight={windowHeight} onSubmit={handleSubmit}>
      <FormContentContainer windowHeight={windowHeight}>
        <RowHolderCenterItem>
          <span style={{ fontSize: pwaStyleAdapter(isTablet ? 24 : 16) }}>{getText(TEXT_KEY.AGE_RESTRICTION)}</span>
        </RowHolderCenterItem>
        <Form.Group className="form-input-group">
          <RowHolder>
            <ColumnHolder>
              <div className="inputField">
                <label className="inputLabel">
                  <Form.Control
                    className={'transparentInput'}
                    autoCapitalize="none"
                    onChange={handleChange(paymentFormKeys.ADDRESS_LINE_1)}
                    onBlur={handleBlur(paymentFormKeys.ADDRESS_LINE_1)}
                    value={values[paymentFormKeys.ADDRESS_LINE_1]}
                    placeholder={paymentFormPlaceHolders[paymentFormKeys.ADDRESS_LINE_1]}
                    style={PurchaseFlow.PaymentInfoFields}
                  />
                </label>
              </div>
            </ColumnHolder>
          </RowHolder>
          <RowHolder>
            <ColumnHolder>
              <div className="inputField">
                <label className="inputLabel">
                  <Form.Control
                    className={'transparentInput'}
                    autoCapitalize="none"
                    onChange={handleChange(paymentFormKeys.ADDRESS_LINE_2)}
                    onBlur={handleBlur(paymentFormKeys.ADDRESS_LINE_2)}
                    value={values[paymentFormKeys.ADDRESS_LINE_2]}
                    placeholder={paymentFormPlaceHolders[paymentFormKeys.ADDRESS_LINE_2]}
                    style={PurchaseFlow.PaymentInfoFields}
                  />
                </label>
              </div>
            </ColumnHolder>
          </RowHolder>
          <LabelHolder>{getText(TEXT_KEY.PAYMENT_INFO_COUNTRY)}</LabelHolder>
          <RowHolderWithLabel>
            <ColumnHolder>
              <div className="inputField">
                <label className="inputLabel">
                  <div className="inputWithDropdown">
                    <Form.Control
                      className={'transparentInput'}
                      autoCapitalize="none"
                      as="select"
                      onChange={handleChange(paymentFormKeys.COUNTRY)}
                      onBlur={handleBlur(paymentFormKeys.COUNTRY)}
                      value={values[paymentFormKeys.COUNTRY]}
                      style={PurchaseFlow.PaymentInfoFields}
                    >
                      {COUNTRY().map(option => {
                        return (
                          <option key={option.value} className="form-input-options" value={option.value}>
                            {option.label}
                          </option>
                        );
                      })}
                    </Form.Control>
                    <DropDownCaret />
                  </div>
                </label>
              </div>
            </ColumnHolder>
          </RowHolderWithLabel>
          <RowHolder>
            <ColumnHolder>
              <div className="inputField">
                <label className="inputLabel">
                  <Form.Control
                    className={'transparentInput'}
                    autoCapitalize="none"
                    onChange={handleChange(paymentFormKeys.CITY)}
                    onBlur={handleBlur(paymentFormKeys.CITY)}
                    value={values[paymentFormKeys.CITY]}
                    placeholder={paymentFormPlaceHolders[paymentFormKeys.CITY]}
                    style={PurchaseFlow.PaymentInfoFields}
                  />
                </label>
              </div>
            </ColumnHolder>
          </RowHolder>
          <LabelHolder>{getText(TEXT_KEY.PAYMENT_INFO_STATE_PROVINCE)}</LabelHolder>
          <RowHolderWithLabel>
            <ColumnHolder>
              <div className="inputField">
                <label className="halfInputField">
                  <div className="inputWithDropdown">
                    <Form.Control
                      className={'transparentInput'}
                      autoCapitalize="none"
                      as="select"
                      onChange={handleChange(paymentFormKeys.STATE)}
                      onBlur={handleBlur(paymentFormKeys.STATE)}
                      value={values[paymentFormKeys.STATE]}
                      style={PurchaseFlow.PaymentInfoFields}
                    >
                      {STATES[values[paymentFormKeys.COUNTRY] || getDefaultCountryValue]().map(option => {
                        return (
                          <option
                            selected={option.value === values[paymentFormKeys.STATE]}
                            key={option.value}
                            className="form-input-options"
                            value={option.value}
                          >
                            {option.label}
                          </option>
                        );
                      })}
                    </Form.Control>
                    <DropDownCaret />
                  </div>
                </label>
              </div>
            </ColumnHolder>
            <ColumnHolder>
              <div className="inputField">
                <label className="halfInputField">
                  <Form.Control
                    className={'transparentInput'}
                    autoCapitalize="none"
                    onChange={handleChange(paymentFormKeys.ZIP)}
                    onBlur={handleBlur(paymentFormKeys.ZIP)}
                    value={values[paymentFormKeys.ZIP]}
                    placeholder={paymentFormPlaceHolders[paymentFormKeys.ZIP]}
                    style={PurchaseFlow.PaymentInfoFields}
                  />
                </label>
              </div>
            </ColumnHolder>
          </RowHolderWithLabel>
          <MessageHolder>{errorMessage && <Message>{errorMessage}</Message>}</MessageHolder>
          <Button
            imageSource={
              isValid ? assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY) : assetSource(ASSET_KEY.ROUNDED_RECT_DISABLED)
            }
            label={getText(TEXT_KEY.CONTINUE_BUTTON)}
            onClick={handleSubmit}
            disabled={!isValid}
            containerStyle={styles.buttonContainer}
            textStroke={BUTTON_STROKES.PRIMARY}
          />
        </Form.Group>
      </FormContentContainer>
    </PaymentForm>
  );
}

const PaymentForm = styled(Form).attrs({
  className: 'form-container payment-info',
})`
  height: ${({ windowHeight }) =>
    !isTablet && isNotStandaloneMobilePWA ? (windowHeight > 630 ? `60vh` : `50vh`) : 'auto'} !important;
`;

const DateOfBirthTitle = styled.p`
  margin-top: -5px;
  margin-bottom: -5px;
  margin-left: 25px;
  color: #d4b8be;
`;

const LabelHolder = styled(DateOfBirthTitle)`
  margin-bottom: -10px;
  font-size: ${pwaStyleAdapter(16)};
`;

const ColumnHolder = styled(Col)`
  padding: 0.1rem 0.5rem;
`;

const RowHolder = styled(Row)`
  margin-right: 15px;
  margin-left: 15px;
  margin-top: 15px;
`;

const RowHolderCenterItem = styled(RowHolder)`
  justify-content: center;
  margin-top: 2.5em;
  margin-bottom: 0.5em;
`;

const RowHolderWithLabel = styled(RowHolder)`
  margin-top: 0.5em;
`;

const MessageHolder = styled(RowHolder)`
  justify-content: center;
  color: red;
  text-align: center;
`;

const Message = styled.div`
  font-size: ${isMobile ? pwaStyleAdapter(16, 'px') : '16px'};
`;

const DropDownCaret = styled.img.attrs(() => ({
  src: assetSource(ASSET_KEY.CARET_DOWN),
  className: 'downIcon',
  alt: 'caret_down',
}))`
  pointer-events: none;
`;

const styles = {
  buttonContainer: {
    margin: '10px auto',
  },
};

