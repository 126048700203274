import { useEffect, useState } from 'react';
import useCheckOrientation from '../../../hooks/useCheckOrientation';
function useUpdateHeight() {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const { isLandscape } = useCheckOrientation();

  useEffect(() => {
    if (isLandscape) {
      setTimeout(() => {
        setWindowHeight(window.innerHeight);
      }, 1000);
    }
  }, [isLandscape]);

  return {
    windowHeight,
  };
}

export default useUpdateHeight;

