import styled, { css } from 'styled-components';
import { isTablet } from 'react-device-detect';
import { isNotStandaloneMobilePWA, isStandalonePWA } from '../../../../utils/mobileSupported';
import { getResponsiveValue } from '../../../../utils/globalMethods';
import { ShadowLayer, Tooltip } from '..';
import {
  CollectGrandStepTooltipTopPosConditions,
  CollectGrandStepTooltipTopPosConditionsTablet,
  CollectGrandStepTopLayerHeightConditions,
  CollectGrandStepTopLayerHeightConditionsTablet,
  CollectGrandStepBottomLayerTopConditions,
  CollectGrandStepBottomLayerTopConditionsTablet,
} from './conditions';

const tooltipSmallScreen = windowHeight => css`
  top: ${getResponsiveValue({
    windowHeight,
    conditions: isTablet ? CollectGrandStepTooltipTopPosConditionsTablet : CollectGrandStepTooltipTopPosConditions,
    defaultValue: isTablet ? 51 : 43,
  })}vh;
  left: 20vh;
`;

const tooltipSmallScreenPWA = css`
  left: 25vh;
  top: 57vh;
`;

const topLayerSmallScreen = windowHeight => css`
  height: ${getResponsiveValue({
    windowHeight,
    conditions: isTablet ? CollectGrandStepTopLayerHeightConditionsTablet : CollectGrandStepTopLayerHeightConditions,
    defaultValue: isTablet ? 65.5 : 57,
  })}vh;
  left: ${isTablet ? 8 : 14}vh;
  width: ${isTablet ? 36.6 : 33.6}vh;
`;

const topLayerSmallScreenPWA = css`
  left: 16vh;
  height: 72vh;
`;

const bottomLayerSmallScreen = windowHeight => css`
  margin-top: ${getResponsiveValue({
    windowHeight,
    conditions: isTablet ? CollectGrandStepBottomLayerTopConditionsTablet : CollectGrandStepBottomLayerTopConditions,
    defaultValue: isTablet ? 66 : 56,
  })}vh;
  left: ${isTablet ? 8 : 14}vh;
  width: ${isTablet ? 36.6 : 33.6}vh;
`;

const bottomLayerSmallScreenPWA = css`
  left: 16vh;
`;

const rightLayerSmallScreen = css`
  left: ${isTablet ? 44.6 : 47.6}vh;
`;

const rightLayerSmallScreenPWA = css`
  left: 55.6vh;
`;

const leftLayerSmallScreen = css`
  width: ${isTablet ? 8 : 14}vh;
`;

const leftLayerSmallScreenPWA = css`
  width: 16vh;
`;

export const COLLECT_GRAND = windowHeight => ({
  TOOLTIP: styled(Tooltip)`
    width: 50vh;
    top: 59vh;
    left: 8vh;
    @media only screen and (min-height: 750px) {
      top: 57vh;
      ${isNotStandaloneMobilePWA && tooltipSmallScreen(windowHeight)}
      ${!isTablet && isStandalonePWA && tooltipSmallScreenPWA}
    }

    @media only screen and (min-height: 950px) {
      top: 55vh;
      ${isNotStandaloneMobilePWA && tooltipSmallScreen(windowHeight)}
    }
    ${isNotStandaloneMobilePWA && tooltipSmallScreen(windowHeight)}
    ${!isTablet && isStandalonePWA && tooltipSmallScreenPWA}
  `,
  TOP: styled(ShadowLayer)`
    height: 73.1vh;
    width: 39.6vh;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 8vh;

    @media only screen and (min-height: 800px) {
      height: 71.2vh;
      ${isNotStandaloneMobilePWA && topLayerSmallScreen(windowHeight)}
    }

    @media only screen and (min-height: 930px) {
      height: 70.5vh;
      ${isNotStandaloneMobilePWA && topLayerSmallScreen(windowHeight)}
    }

    @media only screen and (min-height: 1130px) {
      height: 69vh;
      ${isNotStandaloneMobilePWA && topLayerSmallScreen(windowHeight)}
    }
    ${isNotStandaloneMobilePWA && topLayerSmallScreen(windowHeight)}
    ${!isTablet && isStandalonePWA && topLayerSmallScreenPWA}
  `,
  BOTTOM: styled(ShadowLayer)`
    height: ${isNotStandaloneMobilePWA ? 30 : 20}vh;
    width: 39.6vh;
    top: 77px;
    margin-top: 73.4vh;
    bottom: 0px;
    right: 0px;
    left: 8vh;
    border-bottom-color: #fff;
    border-bottom-width: 4px;
    ${isNotStandaloneMobilePWA && bottomLayerSmallScreen(windowHeight)}
    ${!isTablet && isStandalonePWA && bottomLayerSmallScreenPWA}
  `,
  RIGHT: styled(ShadowLayer)`
    top: 0px;
    bottom: 0px;
    width: 100vw;
    left: 47.6vh;
    ${isNotStandaloneMobilePWA && rightLayerSmallScreen}
    ${!isTablet && isStandalonePWA && rightLayerSmallScreenPWA}
  `,
  LEFT: styled(ShadowLayer)`
    top: 0px;
    bottom: 0px;
    width: 8vh;
    left: 0px;
    ${isNotStandaloneMobilePWA && leftLayerSmallScreen}
    ${!isTablet && isStandalonePWA && leftLayerSmallScreenPWA}
  `,
});

