import React, { Component } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import STier from 'shared/components/Tier';
import ImageBW from '../ImageBoundsWrapper';
import Tooltip from '../Tooltip';
import { TooltipTypes } from 'shared/utils/tooltipConstants';
import Utils from 'shared/utils/index';
import ThemeContext from 'shared/context/ThemeContext';
import Button from '../Button/Button';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import generateRender from 'shared/utils/generateRender';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import ComingSoonTooltip from './components/ComingSoonTooltip';

const CARDS = {
  Bronze: assetSource(ASSET_KEY.CARD_BRONZE),
  Diamond: assetSource(ASSET_KEY.CARD_DIAMOND),
  Gold: assetSource(ASSET_KEY.CARD_GOLD),
  Platinum: assetSource(ASSET_KEY.CARD_PLATINUM),
  Silver: assetSource(ASSET_KEY.CARD_SILVER),
  Premier: assetSource(ASSET_KEY.CARD_PREMIER),
  Elite: assetSource(ASSET_KEY.CARD_ELITE),
  XCARD: assetSource(ASSET_KEY.CARD_XCARD),
  Unlinked: assetSource(ASSET_KEY.TSC_CARDS),
};
export default class Tier extends Component {
  _hoverHandler = changeTooltipType => {
    if (this.props.tooltipType !== TooltipTypes.TIER && this.props.tooltipType !== TooltipTypes.TIER_COMING_SOON) {
      changeTooltipType();
    }
  };

  _closeTooltip = changeTooltipType => {
    changeTooltipType();
  };

  render() {
    const themeContext = this.context;

    const tooltipButtonImageSource = generateRender({
      millelacs: assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY),
      default: assetSource(ASSET_KEY.ROUNDED_RECT_SECONDARY),
    });

    return (
      <STier
        navigation={this.props.navigation}
        {...this.props}
        render={({
          memberLevel,
          isAccountLinked,
          tooltipTitle,
          tooltipButtonLabel,
          clickHandler,
          tooltipClickHandler,
          starDataUnavailable,
          changeTooltipType,
          digitallyVerified,
          assetsUrls,
          linkCardAssets,
          tierInfoTooltipFeature,
        }) => {
          const { showActiveTracker, showMissionsProgressBar } = this.props;

          let asset;
          if (isAccountLinked !== 'yes' || starDataUnavailable) {
            asset = linkCardAssets ? assetsUrls.ImagesCdnBaseUrl + '/' + linkCardAssets : CARDS.Unlinked;
          } else {
            if (linkCardAssets) {
              asset = assetsUrls.ImagesCdnBaseUrl + '/' + linkCardAssets;
            } else {
              asset = digitallyVerified ? assetSource(ASSET_KEY.VIP_TICKET) : CARDS[memberLevel];
            }
          }

          return (
            <TierWrapper
              className="Tier"
              theme={{
                ...themeContext.Tier.TierWrapper,
                ...(showMissionsProgressBar && isMobile && themeContext.Tier.TierWrapperMissionInProgress),
              }}
              showActiveTracker={showActiveTracker}
              onMouseEnter={() => this._hoverHandler(changeTooltipType)}
              onClick={clickHandler}
            >
              {asset && (
                <ImageBW
                  className="TierIcon"
                  source={asset}
                  ibw={require(asset`tier-icon-web.ibw`)}
                  style={{ ...themeContext.Tier.Card, opacity: starDataUnavailable ? 0.3 : 1 }}
                  innerStyle={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                />
              )}
              {tierInfoTooltipFeature && tooltipTitle && (
                <div>
                  <Tooltip
                    style={{
                      marginLeft: showActiveTracker ? '1vmin' : '-9%',
                      ...styles.tooltipContainer,
                    }}
                    arrowStyle={styles.tooltipArrowStyle}
                    visible={this.props.tooltipType && this.props.tooltipType === TooltipTypes.TIER}
                  >
                    <div className="TierCardTooltip">
                      <div className="TierCardTooltipTitle">{tooltipTitle}</div>
                      {tooltipButtonLabel && (
                        <Button
                          imageSource={tooltipButtonImageSource}
                          imageStyle={styles.tooltipButtonImage}
                          containerStyle={styles.tooltipButton}
                          textStyle={styles.tooltipButtonText}
                          label={tooltipButtonLabel}
                          onClick={() => {
                            tooltipClickHandler();
                            this._closeTooltip(changeTooltipType);
                          }}
                          textStroke={BUTTON_STROKES.SECONDARY}
                        />
                      )}
                    </div>
                  </Tooltip>
                </div>
              )}
              <ComingSoonTooltip showActiveTracker={showActiveTracker} tooltipType={this.props.tooltipType} />
            </TierWrapper>
          );
        }}
      />
    );
  }
}

Tier.contextType = ThemeContext;

const TierWrapper = styled.div`
  position: relative;
  padding-left: ${({ showActiveTracker }) => (showActiveTracker ? 35 : 0)}px;
  margin-right: 103px;
  height: 90%;
  margin-top: ${Utils.ieVersion() !== 0 ? 10 : 0}px;
  ${({ theme }) => theme}
`;

const styles = {
  tooltipButton: {
    position: 'relative',
    textAlign: 'center',
    cursor: 'pointer',
    pointerEvents: 'auto',
    justifyContent: 'center',
    marginTop: 5,
  },
  tooltipButtonImage: {
    width: '100%',
  },
  tooltipButtonText: {
    paddingTop: 5,
  },
  tooltipArrowStyle: {
    left: window.innerWidth < 1400 ? 72 : 65,
  },
  tooltipContainer: {
    top: '120px',
    left: window.innerWidth < 1400 ? -40 : 0,
  },
};

