import styled, { css } from 'styled-components';
import { isTablet } from 'react-device-detect';
import { isNotStandaloneMobilePWA, isStandalonePWA } from '../../../../utils/mobileSupported';
import { getResponsiveValue } from '../../../../utils/globalMethods';
import { ShadowLayer, Tooltip } from '..';
import {
  CheckGoalStepTopPosConditions,
  CheckGoalStepTopPosConditionsTablet,
  CheckGoalStepTopLayerHeightConditions,
  CheckGoalStepTopLayerHeightConditionsTablet,
  CheckGoalStepTopLayerLeftConditions,
  CheckGoalStepTopLayerLeftConditionsTablet,
  CheckGoalStepBottomLayerHeightConditions,
  CheckGoalStepBottomLayerHeightConditionsTablet,
  CheckGoalStepRightLayerPosConditions,
  CheckGoalStepRightLayerPosConditionsTablet,
  CheckGoalStepLeftLayerPosConditions,
  CheckGoalStepLeftLayerPosConditionsTablet,
} from './conditions';

const tooltipSmallScreen = windowHeight => css`
  top: ${getResponsiveValue({
    windowHeight,
    conditions: isTablet ? CheckGoalStepTopPosConditionsTablet : CheckGoalStepTopPosConditions,
    defaultValue: isTablet ? 57 : 48,
  })}vh;
  left: ${isTablet ? 75 : 70}vh;
`;

const tooltipSmallScreenPWA = css`
  left: 92vh;
`;

const topLayerSmallScreen = windowHeight => css`
  height: ${getResponsiveValue({
    windowHeight,
    conditions: isTablet ? CheckGoalStepTopLayerHeightConditionsTablet : CheckGoalStepTopLayerHeightConditions,
    defaultValue: isTablet ? 74.5 : 65,
  })}vh;
  left: ${getResponsiveValue({
    windowHeight,
    conditions: isTablet ? CheckGoalStepTopLayerLeftConditionsTablet : CheckGoalStepTopLayerLeftConditions,
    defaultValue: isTablet ? 90 : 84.5,
  })}vh;
  width: ${isTablet ? 35 : 32}vh;
`;

const topLayerSmallScreenPWA = css`
  left: 103vh;
  height: 82vh;
`;

const bottomLayerSmallScreen = windowHeight => css`
  margin-top: ${getResponsiveValue({
    windowHeight,
    conditions: isTablet ? CheckGoalStepBottomLayerHeightConditionsTablet : CheckGoalStepBottomLayerHeightConditions,
    defaultValue: isTablet ? 75.5 : 64,
  })}vh;
  left: ${getResponsiveValue({
    windowHeight,
    conditions: isTablet ? CheckGoalStepTopLayerLeftConditionsTablet : CheckGoalStepTopLayerLeftConditions,
    defaultValue: isTablet ? 90 : 84.5,
  })}vh;
  width: ${isTablet ? 35 : 32}vh;
`;

const bottomLayerSmallScreenPWA = css`
  left: 103vh;
`;

const rightLayerSmallScreen = windowHeight => css`
  left: ${getResponsiveValue({
    windowHeight,
    conditions: isTablet ? CheckGoalStepRightLayerPosConditionsTablet : CheckGoalStepRightLayerPosConditions,
    defaultValue: isTablet ? 125 : 116.5,
  })}vh;
`;

const rightLayerSmallScreenPWA = css`
  left: 142.58vh;
`;

const leftLayerSmallScreen = windowHeight => css`
  width: ${getResponsiveValue({
    windowHeight,
    conditions: isTablet ? CheckGoalStepLeftLayerPosConditionsTablet : CheckGoalStepLeftLayerPosConditions,
    defaultValue: 90,
  })}vh;
`;

const leftLayerSmallScreenPWA = css`
  width: 103vh;
`;

export const CHECK_GOAL = windowHeight => ({
  TOOLTIP: styled(Tooltip)`
    position: absolute;
    z-index: 6;
    width: 50vh;
    top: 68vh;
    left: 84vh;
    @media only screen and (min-height: 900px) {
      top: 65vh;
    }
    ${isNotStandaloneMobilePWA && tooltipSmallScreen(windowHeight)}
    ${!isTablet && isStandalonePWA && tooltipSmallScreenPWA}
  `,
  TOP: styled(ShadowLayer)`
    width: 39.6vh;
    top: 0px;
    height: 83.6vh;
    bottom: 0px;
    right: 0px;
    left: 94.5vh;
    border-bottom-left-radius: 2.1vh;
    border-bottom-right-radius: 2.1vh;

    @media only screen and (min-height: 800px) {
      height: 81.6vh;
      ${isNotStandaloneMobilePWA && topLayerSmallScreen(windowHeight)}
    }

    @media only screen and (min-height: 930px) {
      height: 80.6vh;
      ${isNotStandaloneMobilePWA && topLayerSmallScreen(windowHeight)}
    }

    @media only screen and (min-height: 1130px) {
      height: 79.6vh;
    }
    ${isNotStandaloneMobilePWA && topLayerSmallScreen(windowHeight)}
    ${!isTablet && isStandalonePWA && topLayerSmallScreenPWA}
  `,
  BOTTOM: styled(ShadowLayer)`
    height: 20vh;
    width: 39.6vh;
    top: 77px;
    margin-top: 82vh;
    bottom: 0px;
    right: 0px;
    left: 94.5vh;
    border-bottom-color: #fff;
    border-bottom-width: 4px;
    ${isNotStandaloneMobilePWA && bottomLayerSmallScreen(windowHeight)}
    ${!isTablet && isStandalonePWA && bottomLayerSmallScreenPWA}
  `,
  RIGHT: styled(ShadowLayer)`
    top: 0px;
    bottom: 0px;
    width: 100vw;
    left: 134.1vh;
    ${isNotStandaloneMobilePWA && rightLayerSmallScreen(windowHeight)}
    ${!isTablet && isStandalonePWA && rightLayerSmallScreenPWA}
  `,
  LEFT: styled(ShadowLayer)`
    top: 0px;
    bottom: 0px;
    width: 94.5vh;
    left: 0px;
    ${isNotStandaloneMobilePWA && leftLayerSmallScreen(windowHeight)}
    ${!isTablet && isStandalonePWA && leftLayerSmallScreenPWA}
  `,
});

