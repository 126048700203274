import styled, { css } from 'styled-components';
import { isTablet } from 'react-device-detect';
import { isNotStandaloneMobilePWA, isStandalonePWA } from '../../../../utils/mobileSupported';
import { getResponsiveValue } from '../../../../utils/globalMethods';
import { StartMissionstepTooltipTopPosConditions, StartMissionstepTooltipTopPosConditionsTablet } from './conditions';
import { Tooltip } from '..';

const CompleteRequestMobilePWA = css`
  left: 60vh;
  top: 16vh;
`;

const tooltipSmallScreen = windowHeight => css`
  top: ${getResponsiveValue({
    windowHeight,
    conditions: isTablet ? StartMissionstepTooltipTopPosConditionsTablet : StartMissionstepTooltipTopPosConditions,
    defaultValue: isTablet ? 11 : 14,
  })}vh;
  left: ${isTablet ? 45 : 52}vh;
`;

export const START_MISSION = windowHeight => ({
  TOOLTIP: styled(Tooltip)`
    width: 48vh;
    top: 13vh;
    left: 52vh;
    ${isNotStandaloneMobilePWA && tooltipSmallScreen(windowHeight)}
    ${isStandalonePWA && !isTablet && CompleteRequestMobilePWA}
  `,
});

