import styled from 'styled-components';
import { isMobile, isTablet } from 'react-device-detect';
import { isNotStandaloneMobilePWA } from '../../../../utils/mobileSupported';
import { getResponsiveValue } from '../../../../utils/globalMethods';
import { CheckoutTooltipTopPosConditions, CheckoutTooltipTopPosConditionsTablet } from './conditions';
import { Tooltip } from '..';

export const CHECK_OUT = windowHeight => ({
  TOOLTIP: styled(Tooltip)`
    width: 360px;
    bottom: ${isNotStandaloneMobilePWA
      ? `${getResponsiveValue({
          windowHeight,
          conditions: isTablet ? CheckoutTooltipTopPosConditionsTablet : CheckoutTooltipTopPosConditions,
          defaultValue: isTablet ? 24 : 34,
        })}vh`
      : '160px'};
    right: ${isMobile && !isTablet ? 'calc(41vw - 595px)' : 'calc(50vw - 595px)'};
    z-index: 100;
  `,
});

