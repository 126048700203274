import styled, { css } from 'styled-components';
import { isTablet } from 'react-device-detect';
import { isNotStandaloneMobilePWA, isStandalonePWA } from '../../../../utils/mobileSupported';
import { getResponsiveValue } from '../../../../utils/globalMethods';
import {
  SeeProgressStepTooltipTopPosConditions,
  SeeProgressStepTooltipRightPosConditionsTablet,
  SeeProgressStepTooltipTopPosConditionsTablet,
} from './conditions';
import { Tooltip } from '..';

const isNotStandaloneMobilePWANotTablet = isNotStandaloneMobilePWA && !isTablet;

const tooltipSmallScreenPWA = css`
  right: calc(44vw - 610px);
`;

const tooltipSmallScreenTablet = windowHeight => css`
  right: ${getResponsiveValue({
    windowHeight,
    conditions: SeeProgressStepTooltipRightPosConditionsTablet,
    defaultValue: 'calc(46vw - 610px)',
  })};
  top: ${isNotStandaloneMobilePWA
    ? `${getResponsiveValue({ windowHeight, conditions: SeeProgressStepTooltipTopPosConditionsTablet, defaultValue: 11 })}vh`
    : '106px'};
`;

const tooltipSmallScreen = windowHeight => css`
  top: ${getResponsiveValue({ windowHeight, conditions: SeeProgressStepTooltipTopPosConditions, defaultValue: 15 })}vh;
  right: calc(44vw - 610px);
`;

export const SEE_PROGRESS = windowHeight => ({
  TOOLTIP: styled(Tooltip)`
    width: 410px;
    top: 106px;
    right: calc(50vw - 610px);
    ${isNotStandaloneMobilePWANotTablet && tooltipSmallScreen(windowHeight)}
    ${isStandalonePWA && !isTablet && tooltipSmallScreenPWA}
    ${isTablet && tooltipSmallScreenTablet(windowHeight)}
  `,
});

