import styled, { css } from 'styled-components';
import { isTablet } from 'react-device-detect';
import { isNotStandaloneMobilePWA, isStandalonePWA } from '../../../../utils/mobileSupported';
import { getResponsiveValue } from '../../../../utils/globalMethods';
import { ShadowLayer, Tooltip } from '..';
import {
  CheckGoalStepTopPosConditions,
  CheckGoalStepTopPosConditionsTablet,
  CheckGoalStepTopLayerHeightConditions,
  CheckGoalStepTopLayerHeightConditionsTablet,
  CheckGoalStepBottomLayerHeightConditions,
  CheckGoalStepBottomLayerHeightConditionsTablet,
} from './conditions';

const tooltipSmallScreen = windowHeight => css`
  top: ${getResponsiveValue({
    windowHeight,
    conditions: isTablet ? CheckGoalStepTopPosConditionsTablet : CheckGoalStepTopPosConditions,
    defaultValue: isTablet ? 57 : 48,
  })}vh;
  left: 25vh;
`;

const tooltipSmallScreenPWA = css`
  left: 35vh;
  top: 66vh;
`;

const topLayerSmallScreen = windowHeight => css`
  height: ${getResponsiveValue({
    windowHeight,
    conditions: isTablet ? CheckGoalStepTopLayerHeightConditionsTablet : CheckGoalStepTopLayerHeightConditions,
    defaultValue: isTablet ? 74.5 : 65,
  })}vh;
  left: 50vh;
  width: ${isTablet ? 35 : 31}vh;
`;

const topLayerSmallScreenPWA = css`
  left: 60vh;
  height: 82vh;
`;

const bottomLayerSmallScreen = windowHeight => css`
  margin-top: ${getResponsiveValue({
    windowHeight,
    conditions: isTablet ? CheckGoalStepBottomLayerHeightConditionsTablet : CheckGoalStepBottomLayerHeightConditions,
    defaultValue: isTablet ? 75.5 : 64,
  })}vh;
  left: 50vh;
  width: ${isTablet ? 35 : 31}vh;
`;

const bottomLayerSmallScreenPWA = css`
  left: 60vh;
`;

const rightLayerSmallScreen = css`
  left: ${isTablet ? 85 : 81}vh;
`;

const rightLayerSmallScreenPWA = css`
  left: 99.6vh;
`;

const leftLayerSmallScreen = css`
  width: ${isTablet ? 50.014 : 50}vh;
`;

const leftLayerSmallScreenPWA = css`
  width: 60vh;
`;

export const OPEN_GRADUALLY = windowHeight => ({
  TOOLTIP: styled(Tooltip)`
    width: 58vh;
    top: 68vh;
    left: 30vh;
    ${isNotStandaloneMobilePWA && tooltipSmallScreen(windowHeight)}
    ${!isTablet && isStandalonePWA && tooltipSmallScreenPWA}
  `,
  TOP: styled(ShadowLayer)`
    height: 83.6vh;
    width: 39.6vh;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 51vh;
    border-bottom-left-radius: 2.1vh;
    border-bottom-right-radius: 2.1vh;
    @media only screen and (min-height: 800px) {
      height: 81.6vh;
      ${isNotStandaloneMobilePWA && topLayerSmallScreen(windowHeight)}
    }

    @media only screen and (min-height: 930px) {
      height: 80.6vh;
      ${isNotStandaloneMobilePWA && topLayerSmallScreen(windowHeight)}
    }

    @media only screen and (min-height: 1130px) {
      height: 79.6vh;
    }
    ${isNotStandaloneMobilePWA && topLayerSmallScreen(windowHeight)}
    ${!isTablet && isStandalonePWA && topLayerSmallScreenPWA}
  `,
  BOTTOM: styled(ShadowLayer)`
    height: 20vh;
    width: 39.6vh;
    top: 77px;
    margin-top: 82vh;
    bottom: 0px;
    right: 0px;
    left: 51vh;
    border-bottom-color: #fff;
    border-bottom-width: 4px;
    ${isNotStandaloneMobilePWA && bottomLayerSmallScreen(windowHeight)}
    ${!isTablet && isStandalonePWA && bottomLayerSmallScreenPWA}
  `,
  RIGHT: styled(ShadowLayer)`
    top: 0px;
    bottom: 0px;
    width: 100vw;
    left: 90.6vh;
    ${isNotStandaloneMobilePWA && rightLayerSmallScreen}
    ${!isTablet && isStandalonePWA && rightLayerSmallScreenPWA}
  `,
  LEFT: styled(ShadowLayer)`
    top: 0px;
    bottom: 0px;
    width: 51vh;
    left: 0px;
    ${isNotStandaloneMobilePWA && leftLayerSmallScreen}
    ${!isTablet && isStandalonePWA && leftLayerSmallScreenPWA}
  `,
});

