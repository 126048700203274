import theme from 'shared/assets/style/theme';
import Utils from 'shared/utils/index';
import responsiveFontSize from '../lib/responsiveFontSize';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import { isIOS, isTablet, isMobile } from 'react-device-detect';
import {
  isMobileSupported,
  pwaStyleAdapter,
  isNotStandaloneMobilePWA,
  isStandalonePWA,
  isIPadMini,
} from '../utils/mobileSupported';

const ScrollBar = {
  backgroundColor: theme.palette.primary[10],
  cursor: 'pointer',
  width: '0.5rem',
  borderRadius: '8px',
  position: 'relative',
};

const windowWidth = window.innerWidth;
const windowHeight = window.innerHeight;

const defaultTheme = {
  //TODO: Refactor ScrollBar styles
  AssetPreloader: {
    wideLoadingBarcontainer: {
      top: isNotStandaloneMobilePWA ? '80%' : '50%',
      transform: 'translateY(-50%)',
    },
    ContainerBackground: {
      backgroundPosition: isNotStandaloneMobilePWA ? `center 25%` : 'center',
    },
  },
  AutoFormInput: {
    FormOptions: {
      color: theme.palette.common[0],
      backgroundColor: 'transparent',
    },
  },
  ScrollBar,
  LimitedTimeOffer: {
    LTOWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'center',
      height: '88%',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    MainContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '70%',
      marginBottom: '0.5%',
      justifyContent: 'center',
      alignItems: 'center',
      lineHeight: 1.2,
      fontWeight: 600,
      height: '25%',
    },
    BannerContainer: {
      marginBottom: 0,
      fontWeight: '600',
      marginLeft: '-3%',
    },
    CoinIcon: {
      width: '12%',
      alignSelf: 'center',
      marginRight: '3%',
      marginTop: '0.5%',
    },
    StarIcon: {
      width: '9%',
      alignSelf: 'center',
      marginTop: '0.6%',
    },
    BuyButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    BuyButton: {
      position: 'absolute',
      bottom: '7%',
      transform: 'translateX(-10px)',
    },
    BuyButtonImageStyle: {
      width: windowHeight > 900 && windowWidth < 1300 ? '18vh' : '24vh',
    },
    BuyButtonTextStyle: {
      fontSize: '2.5em',
    },
    ButtonContainer: {
      cursor: 'pointer',
      pointerEvents: 'auto',
      position: 'absolute',
      height: '12%',
      top: '90%',
    },
    BannerContainerText: {
      paddingLeft: '1vmin',
      fontSize: 'calc(14px + 15 * ((100vw - 800px) / 800))',
    },
  },
  ResetPassword: {
    FormInputsFields: {
      background: 'rgba(255, 255, 255, 0.1)',
      border: '1px solid rgba(255, 255, 255, 0.1)',
    },
    InputLabel: {
      color: theme.palette.common[29],
    },
    ModalStyle: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: window.innerWidth < 1400 ? '0 40vmin' : window.innerWidth < 1800 ? '0 60vmin' : '0 70vmin',
    },
  },
  NavigationBar: {
    LoyaltyCurrencyWrapperStyle: {
      position: 'absolute',
      right:
        isMobile && !isTablet ? 'calc(50% - 235px)' : windowWidth < 1300 ? 'calc(50% - 185px)' : 'calc(50% - 205px)',
    },
    LoyaltyCurrencyWrapperStyleWithoutCard: {
      position: 'absolute',
      right: 'calc(50% - 205px)',
    },
    LoyaltyCurrencyIconStyle: {
      height: isTablet && windowWidth > 1150 ? 45 : 50,
      width: isTablet && windowWidth > 1150 ? 45 : 50,
      top: isTablet && windowWidth > 1150 ? -7 : -12,
      left: -5,
    },
    LoyaltyCurrencyIconStyleWithMissionsbar: {
      marginLeft: windowWidth < 1300 ? 0 : '8px',
      ...(isTablet && { left: windowWidth < 1150 ? -30 : -40 }),
    },
    TopBar: {
      height: '5rem',
    },
    BarSection: {
      width: '100%',
      paddingBottom: 0,
      maxWidth: !isMobileSupported() ? (Utils.ieVersion() !== 0 ? 1430 : 1440) : '98%',
      display: 'flex',
    },
    SplittedLeftButton: {
      fontSize: '24pt',
    },
    SplittedRightButton: {
      fontSize: 30,
      paddingRight: 15,
    },
    LevelLabel: {
      width: '100%',
      fontSize: 20,
    },
    BuySaleTextGradient: {
      background: 'linear-gradient(0deg, #caf78b 0%, #caf78b 20%, #fff 60%, #fff 100%)',
      fontSize: 3,
      fontWeight: 800,
      marginTop: 0.6,
    },
    BuySaleSplittedTextGradient: {
      background: 'linear-gradient(0deg, #caf78b 0%, #caf78b 20%, #fff 60%, #fff 100%)',
      fontSize: 2.3,
      fontWeight: 800,
      marginTop: 0.4,
      marginLeft: 0.6,
    },
    DealSplittedTextGradient: {
      background: 'linear-gradient(0deg, #FFA6D4 0%, #FFA6D4 20%, #fff 60%, #fff 100%)',
      fontSize: 2,
      fontWeight: 800,
      marginLeft: -0.8,
      letterSpacing: 1,
    },
    DealTimerText: {
      color: theme.palette.common[0],
      fontWeight: 600,
      transform: 'translate(0px, -8px)',
      paddingRight: 15,
      position: 'relative',
      top: '-0.25rem',
    },
    BuyDealText: {
      lineHeight: 2,
      transform: 'translate(0px, -3px)',
      position: 'relative',
      top: '-0.3rem',
    },
    FullButton: {
      fontSize: '30pt',
      top: 0,
    },
    HomeIcon: {
      height: 50,
      width: 50,
    },
    CoinsCurrencyContainer: {
      width: '60%',
    },
    BuyDealTextStroke: {
      color: BUTTON_STROKES.GREEN,
    },
    SettingIcon: { height: 50, width: 50 },
  },
  SocialHeaderTab: {
    BackgroundImage: {
      bottom: '0.3rem',
    },
  },
  DefaultTooltip: {
    Border: {
      stopColor1: '#d5bd92',
      stopColor2: '#cfab3e',
    },
    Background: {
      stopColor1: '#14012c',
      stopColor2: '#060516',
    },
  },
  Tier: {
    Card: {
      height: isTablet && windowWidth > 1150 ? 40 : 70,
      width: isTablet && windowWidth > 1150 ? 65 : 95,
      position: 'absolute',
      cursor: 'pointer',
      pointerEvents: 'auto',
    },
    TierWrapper: {
      marginRight: windowWidth < 1400 ? '72px !important' : '103px !important',
    },
    TierWrapperMissionInProgress: {
      marginRight: !isTablet ? '100px !important' : 0,
      marginLeft: !isTablet ? 0 : windowWidth < 1150 ? '45px !important' : '15px !important',
    },
    CardAnimation: {
      height: 123,
      width: 150,
      left: -40,
      top: -30,
      zIndex: 10,
    },
  },
  OutOfCoin: {
    QuickBuyOfferDetails: {
      padding: ' 0 10px',
      position: 'absolute',
      bottom: isNotStandaloneMobilePWA ? '18%' : '14%',
      left: 0,
      width: '100%',
      textAlign: 'center',
      boxSizing: 'border-box',
    },
    VisitStoreText: {
      fontSize: 24,
    },
  },
  Settings: {
    HeaderImage: {
      height: '3.7rem',
      width: '100%',
      objectFit: 'contain',
      zIndex: 11,
      position: 'absolute',
      top: 0,
      left: 0,
    },
    HeaderContent: {
      width: ' 100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 5vmin',
    },
    BackArrowContainer: {
      cursor: 'pointer',
      zIndex: 100,
      position: 'absolute',
      height: '2.3rem',
      width: '1.2rem',
      top: '1rem',
      left: '2rem',
    },
    BackgroundLightDivider: {
      backgroundImage: 'none',
    },
    TitleMargin: {
      marginBottom: '0',
    },
    TitleShadow: {
      display: 'flex',
    },
    SignoutButtonText: {
      fontSize: '24px',
    },
  },
  OfferDetail: {
    PurchaseResponseHighlightHeader: {
      fontSize: '2rem',
      textTransform: 'uppercase',
      marginBottom: '10%',
    },
    PurchaseResponseHeader: {
      fontSize: '2rem',
      textTransform: 'uppercase',
      marginBottom: '12%',
      minHeight: '14%',
    },
    RewardCenterPurchaseResponseMessage: {
      marginTop: '0.5em',
      fontSize: '1.4em',
      height: '12%',
    },
    CarouselIndicator: {
      backgroundColor: theme.palette.secondary[41],
      border: `1.5px solid ${theme.palette.secondary[42]}`,
    },
    CarouselIndicatorActive: {
      backgroundColor: theme.palette.secondary[12],
      border: `1.5px solid ${theme.palette.secondary[13]}`,
    },
    CoinsValue: {},
  },
  LinkAccountForm: {
    HeaderImage: {
      height: '3.7rem',
      width: '100%',
      objectFit: 'contain',
      zIndex: 11,
      position: 'absolute',
      top: 0,
      left: 0,
    },
    HeaderTitleContainer: {
      padding: 0,
      margin: 0,
    },
    HeaderTitle: {
      display: 'flex',
      justifyContent: 'center',
      fontSize: '1.8rem',
      fontWeight: 600,
      textTransform: 'uppercase',
      letterSpacing: '1px',
      lineHeight: '3.7rem',
      textShadow: '0px 1px 2px rgba(0, 0, 0, 0.5)',
    },
    NeedHelpText: {
      top: '1.6rem',
    },
    FormContainer: {
      margin: 20,
      maxHeight: '70vh',
    },
    MemberLinkAccountLoginButtonText: {
      fontSize: '24px',
    },
    FormInput: {
      outline: 'none',
    },
  },
  RegisterRewardCard: {
    HeaderImage: {
      height: '3.7rem',
      width: '100%',
      objectFit: 'contain',
      zIndex: 11,
      position: 'absolute',
      top: 0,
      left: 0,
    },
    HeaderTitle: {
      display: 'flex',
      fontSize: '1.9rem',
      fontWeight: 600,
      textTransform: 'uppercase',
      letterSpacing: '1px',
      padding: 0,
      margin: 0,
      justifyContent: 'center',
      lineHeight: '3.7rem',
      textShadow: '0px 1px 2px rgba(0, 0, 0, 0.5)',
    },
    FormDescription: {
      textAlign: 'center',
    },
  },
  BonusLinkMessage: {
    LPBonusContainer: {
      width: '100%',
      textAlign: 'center',
      paddingTop: '376px',
      position: 'absolute',
    },
    BoxContainer: {
      height: '477px',
      width: '100%',
      position: 'relative',
    },
    CoinsBonusContainer: {
      width: '100%',
      textAlign: 'center',
      paddingTop: '392px',
      position: 'absolute',
    },
    BonusLinkButton: {
      position: 'relative',
      width: '100%',
      fontSize: '25px',
      lineHeight: '56px',
      fontWeight: 600,
      textShadow: `1px 1px 0px ${theme.palette.secondary[10]}, 1px -1px 0px ${theme.palette.secondary[10]}, -1px 1px 0px ${theme.palette.secondary[10]}, -1px -1px 0px ${theme.palette.secondary[10]}`,
    },
    BonusInfoContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    CoinImage: {
      position: 'relative',
      width: '28px',
      height: '48px',
      marginRight: '5px',
    },
    BonusText: {
      width: '100%',
      textAlign: 'center',
      marginBottom: '-5px',
    },
  },
  DailySpin: {
    DailyBonusHeader: {
      width: '100%',
      top: '95%',
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    BottomBar: {
      width: '100%',
      height: isMobile ? (windowHeight < 380 ? '26.5%' : '23.5%') : '22%',
    },
    BottomBarContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: isTablet && isNotStandaloneMobilePWA ? '1rem 1rem 1rem 2rem' : '1rem 0',
      width: isTablet && isNotStandaloneMobilePWA ? '100%' : isTablet && isStandalonePWA ? '95%' : '80%',
      height: '100%',
      maxHeight: windowWidth < 1800 ? 170 : 200,
      ...(isTablet && { gap: isNotStandaloneMobilePWA ? '1.5%' : '2.7%' }),
    },
    BottomBarBackgroundWrapperImage: {
      width: '100%',
      height: '100%',
    },
    WheelItems: {
      fontSize: '2.3rem',
      textStroke: `1px ${theme.palette.primary[4]}`,
      WebkitTextStroke: `1px ${theme.palette.primary[4]}`,
    },
    StreakColumnWrapper: {
      bottom: 'auto',
    },
    MilestoneCircleImage: {
      position: 'absolute',
      top: windowHeight < 800 ? -10 : -20,
      height: windowHeight < 800 ? 40 : 55,
      left: 0,
      width: '100%',
      zIndex: 1,
    },
    DaysStreakContainerSmallScreen: {
      padding: '0rem 1rem',
      margin: '0px 1rem',
      maxHeight: windowHeight > 350 ? 350 : windowHeight > 330 ? 330 : windowHeight > 310 ? 310 : 280,
    },
    StreakDaysRowNumber: {
      paddingRight: 1,
      paddingBottom: 1,
    },
    StreakDaysRowBigNumber: {
      paddingRight: 3,
      paddingBottom: 1,
    },
    StreakDaysRowValues: {
      marginTop: 0,
      lineHeight: 'inherit',
    },
    MilestoneRowContainer: {
      marginTop: 0,
    },
    mileStoneRowCoinIcon: {
      height: 24,
      width: 24,
      objectFit: 'contain',
      marginBottom: isMobile ? '0.6rem' : 0,
    },
    mileStoneRowCoinIconInCenter: {
      height: '1.3rem',
      width: '1.3rem',
      marginTop: windowHeight > 970 ? '0.4rem' : '-0.5rem',
      objectFit: 'contain',
    },
    MilestoneCoinsValue: {
      marginBottom: 10,
    },
    MilestoneLoyaltyValue: {
      marginBottom: 0,
    },
    MileStoneRowLoyaltyIcon: {
      height: 40,
      width: 40,
    },
    MilestoneCircleValue: {
      marginTop: (windowWidth < 1500) & (windowHeight < 930) ? '1%' : '0%',
      marginLeft: -1,
    },
    RegDaysItemLoyaltyIcon: {
      height: 24,
      width: 24,
    },
    BottomRightCoinImage: {
      marginBottom: 0,
    },
    SpinButtonText: {
      fontSize: '2.8rem',
    },
    BottomBarLeftContainerWithCard: {
      maxWidth: '150px',
      minWidth: isTablet ? '120px' : 'auto',
    },
    BottomBarLeftContainerNoCard: {
      maxWidth: '150px',
      minWidth: isTablet ? '120px' : 'auto',
    },
    BottomBarLeftContainerWithCardText: {
      fontSize: '0.9rem',
    },
    BottomBarLeftContainerNoCardText: {
      fontSize: '0.9rem',
    },
    BottomBarBottomText: {
      height: '30px',
      marginBottom: windowHeight < 900 ? -10 : -5,
    },
    DailyWheelCollectButton: {
      paddingBottom: '3%',
    },
  },
  FtueTooltip: {
    TooltipTitleStyle: {
      fontSize: '1.3rem',
      fontWeight: '400',
    },
    TooltipRewardStyle: {
      fontSize: '1.4rem',
      fontWeight: '500',
    },
    BiggerBetsStyle: {
      fontSize: '1.3rem',
      fontWeight: '400',
    },
  },
  PurchaseFlow: {
    CreditCardDetails: {
      display: 'flex',
    },
    ContactUsButtonTextStyle: {
      fontSize: 24,
    },
    billingProfileSelect: {
      border: `1px solid ${theme.palette.primary[26]}`,
      borderRadius: 5,
      background: 'rgba(255, 255, 255, 0.1)',
      paddingBottom: 6,
    },
    creditCardField: {
      border: `1px solid ${theme.palette.common[5]}`,
      borderRadius: 5,
      background: 'rgba(255, 255, 255, 0.1)',
      paddingBottom: 6,
      height: pwaStyleAdapter(40),
      fontSize: pwaStyleAdapter(16),
    },
    PurhaseFlowHeader: {
      HeaderContainerStyle: {
        borderTopLeftRadius: 30,
        borderTopRightRadius: 30,
      },
    },
    PaymentInfoFields: {
      borderRadius: pwaStyleAdapter(5),
      height: pwaStyleAdapter(40),
      fontSize: pwaStyleAdapter(16),
    },
    DownArrowIcon: {
      marginLeft: '-1.9em',
      alignSelf: 'center',
      marginTop: '0.5em',
    },
    BillingProfileDownArrowIcon: {
      marginLeft: '-2.5em',
      marginBottom: '0.4em',
    },
    QuestionIconContainer: {
      position: 'absolute',
      top: isTablet ? '37%' : isMobile ? '38%' : '40%',
      left: isTablet ? (windowWidth < 1190 ? '48%' : '58%'): isMobile ? '85%' : '73%',
      ...(isMobile &&
        !isTablet && {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '1.5rem',
          height: '1.5rem',
          top: 'calc(50% - 0.5rem)',
          zIndex: 100,
        }),
    },
  },
  ErrorHandlingWrapper: {
    ButtonTextStyle: {
      fontSize: 24,
    },
  },
  CoinItem: {
    CoinAmount: {
      fontSize: 20,
      lineHeight: '20px',
      fontWeight: 600,
    },
    Extra: {
      fontSize: pwaStyleAdapter(1, 'vmin'),
      marginTop: 0,
      fontWeight: 600,
    },
    BuyButtonText: {
      fontSize: '24px',
    },
  },
  ShopScreen: {
    top: 0,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    left: '0px',
    position: 'absolute',
    width: '100%',
    height: '99%',

    ShopTitle: {
      color: theme.palette.common[15],
      fontSize: '32pt',
      fontWeight: 700,
      textAlign: 'center',
    },

    PurchaseTitle: {
      color: theme.palette.common[15],
      fontSize: '28pt',
      fontWeight: 700,
    },

    Spacer: {
      margin: '0.5rem',
    },

    SaleTimer: {
      position: 'absolute',
      top: '10%',
      right: '5%',
      width: '24%',
      height: '11%',
    },

    ShopContainer: {
      flex: 1,
      margin: '1rem',
      marginTop: '0rem',
      marginBottom: '0rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'stretch',

      ShopHeader: {
        display: 'flex',
        marginTop: '6vmin',
        height: '2rem',
      },

      PackagesContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'stretch',
        padding: '6% 5.5rem 0.4rem',

        TabButtonsContainer: {
          position: 'relative',
          zIndex: 1000,
          display: 'flex',
          top: '14%',
          height: '18%',
          marginTop: '-9.5%',

          TabButton: {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            border: 'none',
            boxShadow: 'none',
            cursor: 'pointer',
            outline: 'none',
            width: '50%',
          },
        },

        CoinItem: {
          margin: isNotStandaloneMobilePWA ? '0.2rem 1rem 0.2rem 0.4rem' : '0.2rem 0.4rem',
          height: '4rem',
          borderRadius: '1rem',
          objectFit: 'stretch',
        },

        InnerItem: {
          color: theme.palette.common[16],
          display: 'flex',
          justifyContent: 'stretch',
          alignItems: 'center',

          Stack: {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'center',
            padding: '4px 0',
            lineHeight: '0.8',
          },
          StackLoyaltyIcon: {
            height: 50,
            width: 30,
            objectFit: 'contain',
            alignSelf: 'center',
          },
          StackAmount: {
            paddingTop: '8%',
            fontSize: '0.9rem',
            textAlign: 'center',
          },
        },
        CoinItemContainer: {
          overflowY: isNotStandaloneMobilePWA ? 'scroll' : 'auto',
          height: 'inherit',
          display: isNotStandaloneMobilePWA ? 'block' : 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        },
        VIPItemContainer: {
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'flex-start',
          overflowY: 'auto',
          overflowX: 'hidden',
          marginLeft: -8,
        },
      },
    },
  },
  MessageDetails: {
    ScrollBar,
    MessageTitle: {
      fontSize: '1.5rem',
      fontWeight: 600,
      textAlign: 'center',
    },
  },
  OfferList: {
    ScrollBar,
  },
  LinkAccount: {
    Description: {
      width: '100%',
      padding: '0 15%',
      fontSize: '12px',
    },
    LinkAccountContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    LinkAccountTitle: {
      fontSize: '1.5rem',
      textTransform: 'uppercase',
      textAlign: 'center',
      fontWeight: 700,
      margin: '0.2em auto',
    },
    AvailableCards: {
      height: '200px',
      display: 'flex',
      flexDirection: 'row',
      margin: '1.5rem 1rem',
    },
    LinkAccountButton: {
      position: 'relative',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
      width: '100%',
      height: '4rem',
    },
    LightDividerUp: {
      display: 'none',
      width: '100%',
    },
    LightDividerDown: {
      display: 'none',
      width: '100%',
      transform: 'rotate(180deg)',
    },
  },
  VIPItem: {
    LogoImage: {
      display: 'none',
    },
    VIPHourTile: {
      width: windowWidth < 1300 ? '35%' : '32%',
      height: isTablet ? '88%' : '90%',
      marginLeft: windowWidth < 1300 ? '1.1rem' : 0,
    },
    PlusLoyaltyText: {
      fontWeight: 'bold',
    },
    ItemPlusOffer: {
      width: '100%',
    },
    LoyaltyPointsLogo: {
      margin: '0 1em',
    },
  },
  MessagesHeader: {
    Image: {
      position: 'absolute',
      top: '-1px',
      left: '-1px',
      height: '3.7rem',
      width: '100%',
      backgroundSize: 'calc(100% + 2px) 100%',
      backgroundImage: `url(${assetSource(ASSET_KEY.FULL_MODAL_HEADER)}`,
    },
  },
  RewardHome: {
    RewardImage: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: '10px',
      backgroundSize: 'cover',
      pointerEvents: 'all',
      cursor: 'pointer',
    },
    RewardHoverOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: '10px',
      transition: '0.1s',
    },
  },
  RewardListItem: {
    RewardImagePlaceholderContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: '10px',
      backgroundSize: 'cover',
    },
    RewardHoverOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: '10px',
      transition: '0.1s',
    },
    OfferCost: {
      position: 'relative',
      padding: '0.5em 0',
      textAlign: 'center',
      flex: 0,
      top: '-25.5%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    OfferName: {
      color: theme.palette.common[1],
    },
    OfferCategoryName: {
      top: '-17%',
    },
    RewardCardContainer: {
      width: '100%',
      height: '100%',
      zIndex: -1,
    },
    RewardCardImage: {
      position: 'relative',
    },
    OfferNameText: {
      paddingTop: 0,
    },
    LoyaltyPointsText: {
      paddingTop: 0,
    },
  },
  CongratulationsContainer: {
    CongratsSpan: {
      fontSize: '1em',
      color: theme.palette.common[12],
    },
  },

  Wheel: {
    Amount: {
      color: theme.palette.common[2],
      textShadow: 'none',
      paddingLeft: 4,
      marginRight:
        windowWidth < 1400 && window.innerHeight < 800 ? '-310px' : window.innerHeight < 900 ? '-340px' : '-420px',
      fontSize:
        windowWidth < 1400 && window.innerHeight < 800 ? '2rem' : window.innerHeight < 900 ? '2.2rem' : '2.5rem',
      fontWeight: 600,
      width: '150px',
    },
  },
  SmartBanner: {
    LogoButtonStyle: {
      objectFit: 'contain',
      height: '100%',
      width: '100%',
    },
  },
  BottomBar: {
    Divider: {
      height: '90%',
      alignSelf: 'flex-end',
      width: 3,
      position: 'relative',
      bottom: 0,
    },
    BottomBarBackground: {
      height: isIOS ? '6rem' : '5rem',
      backgroundImage: `url("${assetSource(ASSET_KEY.WEB_BACKING)}")`,
      bottom: 0,
      alignItems: 'flex-end',
      justifyContent: 'center',
      backgroundPosition: 'center',
    },
    BottomBarContainer: {
      maxWidth: 1140,
      display: 'flex',
      width: '100vw',
      alignItems: 'flex-end',
      marginBottom: isIOS ? '1vw' : 0,
    },
    VIPIcon: {
      height: '5.5em',
    },
    RewardCenterIcon: {
      height: '6em',
    },
    WatchAndEarnIcon: {
      height: '4.5em',
    },
    MailIcon: {
      height: '4.5em',
    },
    SlotsIcon: {
      height: '4.5em',
    },
    BarItemIcon: {
      height: '4.5em',
    },
    ChestOpenBarIcon: {
      height: '4.5em',
      marginBottom: isMobile ? '-0.5em' : '0em',
    },
    ChestClosedBarIcon: {
      height: '4.5em',
      marginBottom: isTablet && isNotStandaloneMobilePWA ? '0em' : isMobile ? '-0.5em' : '0em',
    },
    BarMissionsIcon: {
      height: '4.5em',
    },
    NotificationMail: {
      top: 0,
      left: '1.8em',
    },
    NotificationWatchAndEarn: {
      top: 0,
      left: '2em',
    },
    NotificationMission: {
      top: 0,
      left: '1.3em',
    },
    NotificationEvents: {
      top: windowHeight > 1100 ? '-0.5vh' : windowHeight > 800 ? '-0.7vh' : '-1vh',
      left: pwaStyleAdapter(1.5, 'em', 1.6),
      paddingTop: '0.5rem',
    },
    NotificationText: {
      padding: 0,
    },
    MissionsIconText: {
      height: '0.9em',
      marginTop: 25,
    },
    MissionsIcon: {
      height: '4.5em',
    },
    TimerLabel: {
      position: 'absolute',
      display: 'inline-block',
      width: '100%',
      textAlign: 'center',
      paddingLeft: '15%',
      fontWeight: 600,
      fontSize: isTablet ? '1.1em' : !isIOS ? '0.8em' : '1.5em',
      lineHeight: 1,
      fontFeatureSettings: 'tnum',
      bottom: isTablet ? '0.7rem' : isMobile ? '1.3rem' : !isIOS ? '0.9rem' : '1.5rem',
      zIndex: 2,
    },
    BarItem: {
      position: 'relative',
      cursor: 'pointer',
      paddingBottom: '1rem',
      margin: 0,
      flex: 1,
      justifyContent: 'center',
      alignSelf: 'flex-end',
    },
    BarChestItem: {
      position: 'relative',
      cursor: 'pointer',
      paddingBottom: isTablet && isStandalonePWA ? '0' : '1rem',
      margin: 0,
      flex: 1,
      justifyContent: 'center',
      alignSelf: isTablet ? 'center' : 'flex-end',
      minHeight: 100,
    },
    TimerWrapper: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: 1,
      left: 0,
      bottom: 0,
    },
    TimerBackground: {
      position: 'absolute',
      width: '100%',
      bottom: isTablet ? '0.8em' : '-0.5em',
    },
    TimerCollectBackground: {
      bottom: '-0.5em',
    },
    RewardCenterBadge: {
      zIndex: 1,
      backgroundColor: theme.palette.common[41],
      position: 'absolute',
      top: '7px',
      left: '29%',
      borderRadius: '50%',
      height: '1.5rem',
      width: '1.5rem',
      aspectRatio: 1,
      display: 'flex',
      justifyContent: 'center',
      border: `1.5px solid ${theme.palette.common[1]}`,
      fontSize: '1rem',
      fontWeight: 600,
    },
  },
  StoreDownload: {
    Container: {
      backgroundColor: theme.palette.primary[28],
    },
  },
  GameUnavailable: {
    ButtonText: {
      paddingTop: '5px',
    },
  },
  RewardCenter: {
    RewardCenterMainContentHolder: {
      display: 'flex',
      flexWrap: 'nowrap',
      width: '100%',
      justifyContent: 'space-between',
    },
    Footer: {
      color: theme.palette.common[1],
      textAlign: 'center',
      textShadowColor: theme.palette.common[28],
      textShadowOffset: { width: -1, height: 1 },
      textShadowRadius: 1,
      position: 'absolute',
      top: '91.5%',
      paddingTop: 2,
      fontSize: responsiveFontSize(10, 14),
      width: '100%',
    },
    ActiveTabImage: {
      margin: '0 1em',
    },
    TabImage: {
      margin: '0 1em',
    },
  },
  CreditCardDetails: {
    CheckBoxContainer: {
      cursor: 'pointer',
      display: 'inline-block',
    },
    CheckBoxBackgroundImage: {
      zIndex: 12,
      width: '100%',
      height: '100%',
    },
    BuyNowButton: {
      fontSize: '1.2vw',
    },
    CvvTooltipContainer: {
      left: isTablet ? '67%' : '',
    },
    CvvTooltip: {
      top: 0,
      width: '15rem',
      height: '15rem',
    },
    CvvTooltipArrowOver: {
      bottom: -25,
    },
  },
  LobbyOverlay: {
    MainSceneContainer: {
      paddingTop: isNotStandaloneMobilePWA ? '4.5rem' : pwaStyleAdapter(4.5, 'rem'),
    },
    MobileModalDefaultStyles: {
      marginTop: 'env(safe-area-inset-top)',
      position: 'fixed',
      display: 'flex',
      paddingLeft: '0px',
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center',
    },
  },
  Start: {
    Logo: {
      marginTop: '6%',
    },
    SplashScreenBackground: {
      backgroundPosition: isNotStandaloneMobilePWA ? 'center 25%' : 'center',
    },
    MainContentContainer: {
      marginTop: 30,
    },
    MiddleSectionWrapper: {
      paddingInlineStart: '2rem',
    },
    BottomBarFirstLine: {
      marginTop: 0,
    },
    BottomBarFooterText: {
      marginTop: 0,
      marginBottom: 10,
    },
    MiddleLayoutButtonContainer: {
      margin: isNotStandaloneMobilePWA ? '6rem 0px 0px 0px' : 0,
    },
    ComingSoonBackgrounds: {
      backgroundPosition: 'center, center',
    },
    ComingSoonBackgroundsMobile: {
      backgroundPosition: windowWidth > windowHeight ? 'center 50%, 50%' : 'center 45%, 50%',
    },
  },
  MissionCard: {
    TimerTextWithIcon: {
      fontSize: '2.9vh',
      fontWeight: '700',
      position: 'relative',
      bottom: windowHeight < 310 ? '0' : windowHeight < 320 ? '0.1rem' : windowHeight < 330 ? '0rem' : '0.15rem',
    },
    TimerTextRegular: {
      fontSize: '2.5vh',
      fontWeight: '700',
    },
    DescriptionText: {
      fontSize: isNotStandaloneMobilePWA && !isTablet ? '2vh' : '2.5vh',
    },
    CoinImage: {
      marginTop: isNotStandaloneMobilePWA ? 3 : 0,
    },
  },
  MissionsProgress: {
    MissionIcon: {
      right: windowWidth < 1400 ? 'calc(50% + 40px)' : 'calc(50% + 35px)',
      top: -7,
      height: windowWidth < 1400 ? 55 : 58,
      width: windowWidth < 1400 ? 83 : 93,
    },
    MissionIconWithoutCard: {
      right: 'calc(50% + 40px)',
      top: -10,
      height: 58,
      width: 93,
    },
    TimeBar: {
      right: windowWidth < 1400 ? 'calc(50% - 70px)' : 'calc(50% - 80px)',
      top: 42,
      height: windowWidth < 1400 ? 25 : 32,
      width: windowWidth < 1400 ? 140 : 160,
      zIndex: -1,
    },
    TimeBarWithoutCard: {
      right: 'calc(50% - 82px)',
      top: 40,
      height: 32,
      width: 180,
      zIndex: -1,
    },
    TimeRemainingText: {
      right: windowWidth < 1400 ? 'calc(50% - 43px)' : 'calc(50% - 55px)',
      fontSize: windowWidth < 1400 ? 16 : 20,
      top: 42,
    },
    TimeRemainingTextWithoutCard: {
      right: 'calc(50% - 50px)',
      top: 40,
    },
    TextProgress: {
      color: 'white',
      fontWeight: 700,
      fontSize: 30,
      textShadow: '0 0px 3px #000',
      top: '-0.2rem',
    },
    TextProgressWithoutCard: {
      left: 60,
      top: '-2%',
    },
  },
  Account: {
    Label: {
      fontWeight: '700 !important',
      cursor: 'pointer',
    },
  },
  SignUp: {
    ErrorMessage: {
      color: 'red',
    },
    FormInputTheme: {
      background: 'rgba(255, 255, 255, 0.1)',
      borderRadius: '0.6em',
      border: '1px solid rgba(255,255,255,0.1)',
    },
    MultipleInputInRowWrapper: {
      width: '49%',
    },
    TermsLink: {
      textDecoration: 'underline',
      color: '#a7550e',
      cursor: 'pointer',
      pointerEvents: 'auto',
    },
    NeedHelpLink: {
      color: theme.palette.common[1],
    },
  },
  GameTile: {
    LivePanelItem: {
      height: '50%',
      width: '25%',
    },
    SneakPeekTimerText: {
      opacity: 0.9,
      fontWeight: 700,
    },
  },
  Chest: {
    ChestAnimation: {
      height: 116.78,
      width: 115,
    },
    ChestAnimationEnd: {
      height: 116.78,
      width: 115,
    },
  },
  NotificationModal: {
    HeaderContainer: {
      height: '14%',
      left: 0,
      objectFit: 'contain',
      top: 0,
      width: '100%',
      zIndex: 11,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  Login: {
    ModalContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '70vh',
      minHeight: '320px',
      width: '100%',
      marginTop: '-1vw',
      marginBottom: '-1vw',
      paddingTop: '10vh',
    },
    LoginButtonText: {
      fontSize: '24px',
    },
    HeaderStyle: {
      height: '0px',
    },
    ModalStyle: {
      width: '100vw',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 40vmin',
    },
    BodyStyle: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
      height: '100%',
      padding: '0 2vmin',
    },
    ForgetPasswordLink: {
      color: theme.palette.common[34],
    },
  },
  VideoAds: {
    CloseText: {
      fontSize: '24px',
    },
    KeepWatchingText: {
      fontSize: '17px',
    },
  },
  WatchAndEarnInfo: {
    TimeRemainingText: {
      color: theme.palette.common[22],
      textShadow: `${theme.palette.primary[27]} 1px 1px 4px`,
    },
  },
  WelcomeBonus: {
    CoinIcon: {
      width: '2.8%',
      alignSelf: 'center',
    },
    BonusAmount: {
      textShadow: `2px 2px 4px ${theme.palette.common[0]}`,
      fontWeight: 600,
      marginLeft: '0.4em',
      alignSelf: 'center',
      fontSize: responsiveFontSize(16, 28),
    },
    OfferDetails: {
      padding: '0 10px',
      position: 'absolute',
      bottom: '18.4%',
      left: '-1%',
      width: '100%',
      textAlign: 'center',
      boxSizing: 'border-box',
    },
  },
  TermsModal: {
    SocialErrorText: {
      fontSize: 24,
    },
    ContactUsButtonTextStyle: {
      fontSize: 24,
    },
    TermsLinkText: {
      color: theme.palette.common[1],
    },
    TermsAndPrivacyModal: {
      width: '100vw',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: isTablet ? (isIPadMini ? '0 35vmin' : '0 10vw') : '0 40vmin',
    },
  },
  VipAccessFlow: {
    VipAccessImage: {
      width: '100%',
    },
    VipAccessMultipleImageWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    VipAccessTwoImagesWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '40%',
    },
    VipAccessButtonImage: {
      width: 180,
    },
    VipAccessButtonText: {
      width: '90%',
      lineHeight: 1,
    },
    VipAccessRedeemButtonText: {
      width: '90%',
      lineHeight: 1,
    },
  },
  OnboardingLayer: {
    MessageTitleTooltip: {
      textAlign: 'center',
      fontSize: '1.5rem',
      display: 'flex',
      fontWeight: 600,
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
  },
  TimeRemaining: {
    TimerLabel: {
      color: theme.palette.secondary[14],
      letterSpacing: '0.05rem',
      lineHeight: '4rem',
      fontFeatureSettings: 'tnum',
      fontSize: '1.1rem',
      paddingRight: '1.8rem',
      position: 'absolute',
      left: '0.7rem',
      textAlign: 'right',
      top: '-0.4rem',
      width: '100%',
    },
    TimerLabelMobile: {
      color: theme.palette.common[1],
      letterSpacing: '0.05rem',
      lineHeight: '4rem',
      fontFeatureSettings: 'tnum',
      fontSize: pwaStyleAdapter(1, 'rem'),
      position: 'absolute',
      left: '3rem',
    },
    MobileVipTimerContainer: {
      width: '10.5rem',
    },
    VIPTimer: {
      top: pwaStyleAdapter(4, 'rem'),
    },
  },
  JSModal: {
    ModalBackgroundAsset: {
      widthScale: 0.3,
    },
  },
  DynamicPopupsButtons: {
    ButtonsContainer: {
      width: '100%',
      justifyContent: 'center',
      flexDirection: 'row',
      flexWrap: 'wrap',
      position: 'absolute',
      bottom: '10%',
    },
    RowWrapperLink: {
      position: 'absolute',
      bottom: '17%',
      paddingInline: '22%',
    },
  },
  TermsModalWrapper: {
    Modal: {
      padding: isTablet ? '10vmin' : windowWidth < 700 ? '0 4vmin !important' : '0 20vmin !important',
    },
    NeedHelpLink: {
      fontSize: '15px',
      fontWeight: '600',
      color: theme.palette.common[1],
    },
  },
  MessageItem: {
    Title: {
      fontSize: 26,
      display: 'inline',
    },
    Subtitle: {
      margin: 0,
    },
  },
  Events: {
    TimeLeftText: {
      marginTop: 0,
    },
  },
  BillingProfile: {
    AddDeleteProfileText: {
      fontSize: '2vmin',
    },
    DownArrowIcon: {
      marginLeft: '-2.5em',
      marginBottom: '0.4em',
    },
  },
  SideBar: {
    MenuContainer: {
      height: isTablet ? 'calc(100vh - 7rem - 7rem)' : isMobile ? '100%' : 'calc(100vh - 5rem - 5rem)', // 100vh - bottom bar height - top bar height
      marginTop: 0,
      maxHeight: !isMobile ? '80vh' : '',
      marginLeft: isNotStandaloneMobilePWA ? '-3vh' : '-1vh',
      borderBottomRightRadius: '10vh',
      borderTopRightRadius: '10vh',
      border: '5px solid #6b3095',
      background: 'linear-gradient(to bottom, #2b004a 0%, #330058 25%, #50008a 50%, #330058 75%, #2b004a 100%)',
    },
    MenuItem: {
      padding: isIOS && !isTablet ? '0 0 0 7.1rem' : '0 0 0 4rem',
    },
    ItemButton: {
      zIndex: -1,
      position: 'absolute',
      left: 0,
    },
    MenuItemText: {
      fontSize: isStandalonePWA && !isTablet ? '2.5vw' : isMobile && !isTablet ? '2vw' : '3vw',
    },
  },
  Bar: {
    BarContainer: {
      background: 'rgb(33, 30, 28)',
    },
    BarText: {
      alignSelf: 'center',
      fontSize: windowWidth > 1700 ? '20px' : '18px',
      marginLeft: 'auto',
      paddingLeft: 15,
    },
    BarProgress: {
      background: 'linear-gradient(to bottom right, #7560ae 0%, #662d91 60%)',
    },
  },
  CurrencyDisplay: {
    Icons: {
      loyalty: {
        height: 80,
        width: 80,
        position: 'absolute',
        left: -20,
      },
      coins: {
        height: 50,
        width: 50,
        position: 'absolute',
        left: -20,
      },
    },
    CoinsLabel: {
      fontSize: 20,
      paddingLeft: isTablet ? 15 : 5,
    },
    LoyaltyLabel: {
      fontSize: 20,
      left: 70,
    },
  },
  ForgotPassword: {
    ModalContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '70vh',
      width: '100%',
      marginTop: '-1vw',
      marginBottom: '-1vw',
    },
    ModalBody: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      padding: '0 2vmin',
    },
    ModalMainBodyStyle: {
      marginTop: isNotStandaloneMobilePWA ? '-25vh' : -100,
    },
  },
  OfferDetailDescription: {
    ButtonIcon: {
      width: '4em',
      marginBottom: 3,
      marginLeft: -8,
    },
    ButtonText: {
      paddingBottom: '2%',
    },
  },
  MessageList: {
    ScrollBarCustomStyle: {
      height: '70%',
    },
  },
  MissionHelp: {
    ModalStyle: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '6vh 16vw 0',
      zIndex: '10',
    },
  },
  MessageContainer: {
    TitleText: {
      margin: '0 auto',
      fontSize: '2.8rem',
      background: 'linear-gradient(to bottom, rgb(237, 237, 130) 35%, rgb(214, 177, 104) 100%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      fontWeight: 900,
      textStroke: '2px #4d4729',
      WebkitTextStroke: '2px #4d4729',
      textTransform: 'uppercase',
    },
  },
  SportsAccessFlow: {
    SportsAccessButtonImage: {
      width: 180,
    },
    SportsAccessRedeemButtonText: {
      width: '90%',
      lineHeight: 1,
    },
  },
  FavouritesInformation: {
    LevelUpText: {
      marginBottom: -5,
    },
  },
};

export default defaultTheme;

