import React, { useContext } from 'react';
import { Container, LogoAsset } from './styledComponents';
import ThemeContext from 'shared/context/ThemeContext';
import SmartBanner from '../../../components/SmartBanner';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import BadgeContainer from './BadgeContainer';
import { isMobileSupported } from '../../../utils/mobileSupported';

const DefaultStoreDownload = () => {
  const { StoreDownload } = useContext(ThemeContext);
  return (
    <div>
      <SmartBanner style={{ position: 'unset' }} />
      <Container theme={StoreDownload.Container}>
        <LogoAsset src={assetSource(ASSET_KEY.LOGO)} alt="logo" />
        <p>Experience the thrill of an exciting new gaming experience!</p>
        {ENVIRONMENT_VARIABLES.APPLE_STORE_URL && !isMobileSupported() && (
          <BadgeContainer url={ENVIRONMENT_VARIABLES.APPLE_STORE_URL} badge={ASSET_KEY.BADGE_I_OS} />
        )}
        {ENVIRONMENT_VARIABLES.ANDROID_STORE_URL && (
          <BadgeContainer url={ENVIRONMENT_VARIABLES.ANDROID_STORE_URL} badge={ASSET_KEY.BADGE_ANDROID} />
        )}
      </Container>
    </div>
  );
};
export default DefaultStoreDownload;

