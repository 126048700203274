import React, { useState } from 'react';
import styled from 'styled-components';

const Tab = ({ children }) => {
  return <TabContainer>{children}</TabContainer>;
};

const Navigator = ({ headerComponent, children }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child, {
          Header: headerComponent,
          activeTab,
          active: index === activeTab,
          onClick: () => setActiveTab(index),
        })
      )}
    </>
  );
};

const Screen = ({ component, active, Header, ...props }) => {
  return (
    <>
      <Header {...props} />
      <ScreenContainer active={active}>{component}</ScreenContainer>
    </>
  );
};

Tab.Navigator = Navigator;
Tab.Screen = Screen;
export default Tab;

const TabContainer = styled.div`
  height: 70vh;
  width: 85%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  padding-top: 6vh;
  margin: 0 auto;
`;

const ScreenContainer = styled.div`
  position: absolute;
  height: 95%;
  width: 100%;
  z-index: 5;
  bottom: 0;
  display: ${({ active }) => (active ? 'flex' : 'none')};
  padding: 5rem;
  padding-bottom: 0;
`;

